import { InjectionToken } from '@angular/core';
import { addDays, areIntervalsOverlapping, format, formatISO, isAfter, isBefore } from 'date-fns';
import type { Interval } from 'date-fns/types';

export interface DateProvider {
  toISOString(date?: string | Date): string;
  toDate(source?: string | Date): Date;
  addDays(days: number, source?: Date): Date;
  format(date?: string | Date, pattern?: string): string;
  isBefore(date: string | Date, dateToCompare: string | Date): boolean;
  isAfter(date: string | Date, dateToCompare: string | Date): boolean;
  areIntervalsOverlapping(intervalLeft: Interval, intervalRight: Interval): boolean;
}

export const DATE_PROVIDER = new InjectionToken<DateProvider>('Date provider');

export class RealDateProvider implements DateProvider {
  isBefore(date: string | Date, dateToCompare: string | Date): boolean {
    return isBefore(date, dateToCompare);
  }

  isAfter(date: string | Date, dateToCompare: string | Date): boolean {
    return isAfter(date, dateToCompare);
  }

  toISOString(date?: Date | string): string {
    if (date) {
      return formatISO(new Date(date));
    }
    return formatISO(new Date());
  }

  toDate(date?: string): Date {
    if (date) {
      return new Date(date);
    }
    return new Date();
  }

  format(source?: string | Date, formatPattern = 'dd/MM/yyyy'): string {
    const date = source ? new Date(source) : new Date();
    return format(date, formatPattern);
  }

  addDays(days: number, source = new Date()): Date {
    return addDays(source, days);
  }

  areIntervalsOverlapping(intervalLeft: Interval, intervalRight: Interval): boolean {
    return areIntervalsOverlapping(intervalLeft, intervalRight);
  }
}
